import { Container } from "@material-ui/core";
import MainCarousel from "./components/carousel/MainCarousel";
import MainNav from "./components/navigation/MainNav";
import TopBar from "./components/navigation/TopBar";

function App() {
  return (
    <>
      <Container fixed>
        <TopBar />
        <MainCarousel />
        <MainNav />
      </Container>
    </>
  );
}

export default App;
