import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CompanyAbout from "./CompanyAbout";
import DJAbout from "./aboutdj/DJAbout";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="div">
            <Box
              letterSpacing={4}
              textAlign="center"
              fontSize="h4.fontSize"
              m={1}
            >
              About Envision DJ
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CompanyAbout />
        </Grid>
        <Grid item xs={12}>
          <DJAbout />
        </Grid>
      </Grid>
    </div>
  );
}
