import Slide from "./Slide";
import Carousel from "react-material-ui-carousel";

const MainCarousel = () => {
  let slides = [
    {
      title: "Image 1",
      description: "Some information about Image 1",
      image:
        "https://source.unsplash.com/random",
    },
    {
      title: "Image 2",
      description: "Some information about Image 2",
      image:
        "https://source.unsplash.com/random",
    },
    {
      title: "Image 3",
      description: "Some information about Image 3",
      image:
        "https://source.unsplash.com/random",
    },
  ];
  return (
    <Carousel indicators={false} navButtonsAlwaysInvisible={true}>
      {slides.map((slide) => (
        <Slide slide={slide} />
      ))}
    </Carousel>
  );
};

export default MainCarousel;
