import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    height: "40vh",
  },
  media: {
    height: "40vh",
  },
});

const Slide = (props) => {
  const classes = useStyles();
  const { title, image } = props.slide;
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={image} title={title} />
      </CardActionArea>
    </Card>
  );
};

export default Slide;
