import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  image: {
    margin: 'auto',
    width: 240,
    height: 300,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

const image =
  "https://scontent-den4-1.xx.fbcdn.net/v/t1.0-9/29388723_10210456647678538_3669351313008754688_n.jpg?_nc_cat=105&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=tKFwgh8PAIgAX8ck00z&_nc_ht=scontent-den4-1.xx&oh=edf50859a1c59ee3fadd8476da5e33ac&oe=608415C4";

export default function DJAbout() {
  const classes = useStyles();

  return (
    <div className={classes.image}>
      <img className={classes.img} alt="Ryan Dohoney" src={image} />
    </div>
  );
}
