import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function CompanyAbout() {
  return (
    <Typography component="div">
      <Box letterSpacing={4} textAlign="left" fontSize="h5.fontSize" m={1}>
        Envision DJ
      </Box>
      <Box textAlign="left" m={1}>
        I'm baby iPhone PBR&B mixtape, cloud bread kombucha authentic disrupt
        XOXO crucifix next level swag kinfolk. Meh normcore neutra offal tousled
        keytar kinfolk taiyaki PBR&B messenger bag copper mug. Hexagon godard
        banh mi slow-carb chicharrones kale chips fashion axe truffaut
        intelligentsia cred small batch vinyl. IPhone health goth paleo pop-up,
        gentrify photo booth meditation raw denim irony. Deep v meditation vice
        everyday carry, paleo retro tbh aesthetic swag. Fingerstache letterpress
        iPhone, sartorial listicle bespoke locavore chambray XOXO tumblr
        cornhole.
      </Box>
    </Typography>
  );
}
