import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DJImage from "./DJImage";

export default function DJAbout() {
  return (
    <Grid container >
      <Grid item xs={12} md={4}>
        <Typography component="div">
          <Box letterSpacing={4} textAlign="center" fontSize="h5.fontSize" m={1}>
            Ryan Dohoney
          </Box>
        </Typography>
        <DJImage />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography component="div">
          <Box letterSpacing={4} textAlign="left" fontSize="h5.fontSize" m={1}>
            About Ryan
          </Box>
          <Box textAlign="left" m={1}>
            I'm baby iPhone PBR&B mixtape, cloud bread kombucha authentic
            disrupt XOXO crucifix next level swag kinfolk. Meh normcore neutra
            offal tousled keytar kinfolk taiyaki PBR&B messenger bag copper mug.
            Hexagon godard banh mi slow-carb chicharrones kale chips fashion axe
            truffaut intelligentsia cred small batch vinyl. IPhone health goth
            paleo pop-up, gentrify photo booth meditation raw denim irony. Deep
            v meditation vice everyday carry, paleo retro tbh aesthetic swag.
            Fingerstache letterpress iPhone, sartorial listicle bespoke locavore
            chambray XOXO tumblr cornhole.
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
